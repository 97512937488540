@font-face {
  font-family: 'GOST type B';
  src: url('./gost_b.ttf');
  font-weight: normal;
  font-style: normal;
}

html, body {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
}
html, body, input, textarea, select, button {
  color: rgb(48, 57, 66);
  font-family: Roboto, Arial, sans-serif;
  font-size: 100%;
  line-height: 21px;
  text-rendering: optimizeLegibility;
}
.splash {
  width: 512px;
  height: 128px;
  position: absolute;
  left: 48%;
  margin-left: -255px;
  margin-top: 140px;
  background-image: url(splash.gif);
}
.description {
  padding-left: 120px;
  font-size: 67%;
  line-height: 120%;
  color: #555;
}
.description p, .description ul, .description h1 {
  margin: 0;
}
.description ul {
  padding-left: 16px;
}
.description h1 {
  margin-top: 0;
  margin-bottom: 0.6em;
  font-weight: 500;
}
@media (max-width: 640px) {
  .splash {
    width: 256px;
    height: 64px;
    margin-left: -128px;
    margin-top: 100px;
    background-size: cover;
  }
  .description {
    padding-left: 50px;
  }
}
